import * as React from "react"

function TaglineDesktop(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1224.54 283.27"
      //   width={1224.54}
      //   height={283.27}
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M98.46 17.56l5.62 5.62a11.66 11.66 0 00-1.1-1l-5.62-5.62a11.66 11.66 0 011.1 1z"
            fill="none"
          />
        </clipPath>
        <clipPath id="prefix__b">
          <path
            d="M97.53 16.64l5.62 5.62a12.29 12.29 0 00-1.09-1l-5.62-5.62a12.29 12.29 0 011.09 1z"
            fill="none"
          />
        </clipPath>
        <clipPath id="prefix__c">
          <path
            d="M95.68 14.78l5.62 5.62c-.49-.48-.89-.82-1.1-1l-5.62-5.62c.21.22.61.52 1.1 1z"
            fill="none"
          />
        </clipPath>
      </defs>
      <path d="M379.71 152.37v20.21c-5.7-6.27-6.89-6.55-13.62-6.55h-2.18c-3.7 0-7.29.12-10.88.12v18.17h22.88v14.93H353v48.32h16.41c5.64 0 6.57-2.07 10.47-6.32v20.44h-48.33v-99.1c0-4.94-.51-6.09-2.66-10.22z" />
      <path
        d="M335 258.37v-95.76a21.3 21.3 0 00-.82-6.86h42.23v9.1c-2.85-1.92-5.45-2.16-10.25-2.16h-2.2c-1.88 0-3.76 0-5.57.06s-3.55.06-5.31.06a3.36 3.36 0 00-3.36 3.36v18.18a3.36 3.36 0 003.36 3.36h19.53v8.21H353a3.36 3.36 0 00-3.36 3.36v48.32A3.37 3.37 0 00353 251h16.42a12.94 12.94 0 007.11-1.69v9.09z"
        fill="#c51f30"
      />
      <path d="M448.86 161.68c5 10.51 8.52 37 8.51 60 0 8.38-1.26 23.2-5.07 30.43-4.62 8.73-11.52 9.65-19.83 9.65H400.1v-99.85c0-4.14-.82-6.09-2.67-9.53h33.8c7.21-.01 13.77 1.3 17.63 9.3zm-16 82c3.14-6.18 3.14-17.42 3.14-22.47 0-9.88.81-54.49-11.4-54.49h-3v80.67h2.67c3.28 0 6.88-.28 8.62-3.67" />
      <path
        d="M403.5 258.42v-96.5a20 20 0 00-.84-6.17h28.61c6.82 0 11.63 1.24 14.57 7.39 4.6 9.64 8.2 35.36 8.2 58.56 0 8-1.24 22.34-4.67 28.87s-8.25 7.85-16.85 7.85zm18.09-95a3.37 3.37 0 00-3.37 3.37v80.68a3.37 3.37 0 003.37 3.36h2.67c2.85 0 8.79 0 11.61-5.51 3.48-6.79 3.48-18.2 3.47-23.68v-2.08c.07-20.78-.85-45.63-8.62-53.43a8.63 8.63 0 00-6.17-2.71z"
        fill="#c51f30"
      />
      <path d="M311.35 152.37l-21.85 30.81c10.79 4.9 15.28 14.14 16.38 29 1.23 16.71 1.31 32.29 1.29 42a13.89 13.89 0 002.22 7.17h-23.68l.1-16.3c.11-9.08.77-42.17-8.06-47.42a9.82 9.82 0 00-3.82-1v64.71h-21.47v-99.05c0-4.59-.72-6.08-2.67-10h24.12v26.15c3.29-4.25 12.42-14.55 12.42-20.06a22.07 22.07 0 00-1.43-6.09z" />
      <path
        d="M289.13 258.09l.09-12.93v-.78c.37-30-2.63-45.33-9.72-49.55a13.1 13.1 0 00-5.37-1.46H274a3.35 3.35 0 00-2.31.91 3.38 3.38 0 00-1.05 2.45v61.36h-14.78v-95.71a20.32 20.32 0 00-.87-6.63h15.6v22.8a3.37 3.37 0 002.27 3.18 3.41 3.41 0 001.09.18 3.37 3.37 0 002.66-1.31c.49-.64 1.12-1.42 1.83-2.3 5.95-7.36 11.29-14.42 11.29-19.82a13.84 13.84 0 00-.31-2.73h15.44l-18.08 25.51a3.39 3.39 0 00-.51 2.83 3.43 3.43 0 001.86 2.18c9.18 4.16 13.36 11.76 14.43 26.22 1.2 16.27 1.32 31.64 1.3 41.78a14.36 14.36 0 00.49 3.82z"
        fill="#c51f30"
      />
      <path
        d="M44.82 175c-.6-.08-1.24-.13-1.92-.16.68.04 1.32.09 1.92.16zM47.37 175.54a12.2 12.2 0 00-1.66-.4 12.2 12.2 0 011.66.4zM48.93 176.16a14.13 14.13 0 013.82 2.84 14.13 14.13 0 00-3.82-2.84zM43.9 174.08c-.61-.08-1.25-.12-1.93-.16.68.08 1.32.08 1.93.16zM46.45 174.61a12.71 12.71 0 00-1.67-.4 12.71 12.71 0 011.67.4z"
        fill="none"
      />
      <path
        d="M48 175.23a14.25 14.25 0 013.81 2.85 14.25 14.25 0 00-3.81-2.85zM42 172.22zM44.59 172.75a13.89 13.89 0 00-1.66-.39 13.89 13.89 0 011.66.39zM46.15 173.37a14.56 14.56 0 013.85 2.86 14.72 14.72 0 00-3.85-2.86z"
        fill="none"
      />
      <path d="M47 152.37v21.26c-5.75-7.25-8.72-7.25-17.14-7.25-6.16 0-7.69 2.42-7.7 9.43 0 9.18 7.29 10.55 13.54 14.3 8.57 5.13 13.78 16.75 14.46 29.94 1.11 21.39-1.51 41.24-18.39 41.24H3l-.5-22.74c6.56 6.54 9.62 8.15 16.31 8.15 8.49 0 10.05-10.29 10.06-23.55 0-10.34-5.09-16.83-12.11-19.73C6.28 199.06 0 190.53 0 175.92c0-15.51 6.34-23.55 18.43-23.55z" />
      <path
        d="M6.36 258l-.26-11.78c3.79 2.79 7.32 3.88 12.78 3.88 13.41 0 13.41-18.18 13.41-26.92 0-10.78-5.17-19.1-14.18-22.84C8.2 196.23 3.38 188.25 3.38 176c0-6.95 1.33-12.17 4-15.53 2.46-3.14 6.09-4.67 11.1-4.67h25.17v9.95c-4-2.66-7.89-2.66-13.77-2.66-9.72 0-11.06 6.15-11.06 12.79 0 9.4 6.41 12.64 11.56 15.25 1.21.61 2.46 1.24 3.62 1.94 7.27 4.36 12.19 14.79 12.84 27.23.92 17.7-1.07 28.88-6.1 34.18a11.63 11.63 0 01-8.92 3.52z"
        fill="#c51f30"
      />
      <path d="M150.44 267.53c0 5.4 1.15 11.4 3.39 15.55a20.07 20.07 0 01-2.83.19 20.61 20.61 0 01-8.7-1.84c-3.18-1.55-4.88-3.21-8.25-4.33a9.29 9.29 0 00-2.88-.39 18.15 18.15 0 00-4.62.62 25.33 25.33 0 002.32-11c0-8.32-.06-77.06-.07-80.68L109 254.27 87.65 188c0 3.21.28 64.36.28 67a15.3 15.3 0 002 6.78H72.68V160.66c0-2.87-1.25-5.89-2.37-8.27H96.9l14.64 46.22 13.95-46.22h25.13c0 5.05-.18 101.38-.18 115.14z" />
      <path
        d="M148.91 279.83a16.23 16.23 0 01-5.09-1.39 33.52 33.52 0 01-3-1.69 25.71 25.71 0 00-5.66-2.8 12.08 12.08 0 00-3.66-.56 32.09 32.09 0 00.77-7c0-8.23-.09-80-.09-80.7a3.37 3.37 0 00-2.9-3.33 3.71 3.71 0 00-.47 0 3.36 3.36 0 00-3.23 2.43l-16.74 58.06L90.87 187a3.36 3.36 0 00-3.2-2.33 3.08 3.08 0 00-.53 0A3.38 3.38 0 0084.3 188c0 .64.3 64.39.3 67a13.65 13.65 0 00.44 3.41h-9v-97.77a17.21 17.21 0 00-.79-4.91h19.19l13.92 43.88a3.36 3.36 0 003.2 2.35 3.35 3.35 0 003.19-2.39L128 155.75h19.27c0 17.72-.15 99.64-.15 111.82a42.19 42.19 0 001.79 12.26z"
        fill="#c51f30"
      />
      <path
        d="M197.2 246.35a9.88 9.88 0 002 3.16l5.62 5.62-5.62-5.62a9.88 9.88 0 01-2-3.16zM194.66 234.35c-.11-1-.2-2.13-.29-3.22.09 1.09.18 2.17.29 3.22zM195 237.43c-.14-1-.27-2-.38-3.08.15 1.05.28 2.08.38 3.08zM196.8 245.31z"
        fill="none"
      />
      <path d="M201.08 152.37c26.26 0 31.18 26.2 31.17 54.38 0 31.21-4.85 57.87-30.5 57.87-26.06 0-30.36-24.35-30.35-57 .01-26.73 4.43-55.25 29.68-55.25zm.78 98.36c9.75 0 7.91-31.1 7.92-43.29 0-15 .47-40.83-7.94-40.83s-8 24.76-8 39c0 12.6-.93 45.13 8 45.13" />
      <path
        d="M201.8 261.3c-10.79 0-17.74-4.52-21.87-14.21-4.56-10.69-5.15-26.67-5.15-39.4 0-35.44 8.36-51.94 26.3-51.94 10.8 0 17.91 4.65 22.39 14.64 3.72 8.3 5.45 19.86 5.45 36.39 0 38.22-8.11 54.52-27.12 54.52zm0-98a7.51 7.51 0 00-5.39 2.31c-5.53 5.54-6 20.41-5.93 37.84v5.48c-.09 18.48.32 37 6.28 43a7.07 7.07 0 005.1 2.22 7.7 7.7 0 005.59-2.42c5.77-5.85 6-21.81 5.73-39 0-2-.05-3.72-.05-5.19v-2.22c0-18.39-.41-34.07-6-39.7a7.38 7.38 0 00-5.28-2.35z"
        fill="#c51f30"
      />
      <path d="M908.9 189c10.27 2.88 12.41 29.24 12.41 50.74 0 20.22-7.7 21.71-23.41 21.71h-30.16v-99.39c0-5-.51-5.86-2.67-10.22H896c12.42 0 22.8 4.55 23.69 18.83.52 8.9-2.26 15.87-10.79 18.33zm-19.71 58.78c7.8 0 11.16-.91 11.16-10.33 0-19.21-.53-42-8.63-42h-2.52v52.3m0-81.59v15.05h1.33c4.72 0 8.93-1.59 8.57-7-.39-5.91-3.12-8.16-7.59-8.16h-.36l-1.95.11" />
      <path
        d="M871.14 258.07V162a20.23 20.23 0 00-.84-6.86H896c6.11 0 10.91 1.17 14.27 3.47 3.74 2.56 5.72 6.56 6.08 12.21.69 11.05-4.42 13.79-8.32 14.9a3.36 3.36 0 000 6.47c3.13.88 5.93 6.63 7.66 15.77 1.52 8.08 2.3 18.76 2.3 31.74 0 17.46-5.15 18.34-20 18.34zm18.09-66a3.37 3.37 0 00-3.37 3.37v52.31a3.37 3.37 0 003.37 3.36c7.94 0 14.52-1 14.52-13.7 0-24.8-.9-45.34-12-45.34zm2.3-29.41H891l-2 .12a3.36 3.36 0 00-3.17 3.35v15.05a3.36 3.36 0 003.37 3.36h1.33c4.29 0 7.52-1.12 9.6-3.34a9.34 9.34 0 002.33-7.25c-.25-4.19-2.05-11.27-10.93-11.27z"
        fill="#c51f30"
      />
      <path d="M1224.54 151.79v109.1h-18.14l-21.3-69.25c0 7.24-.13 72.38-.1 76a23.76 23.76 0 002 9.4 9.83 9.83 0 00-2-.18 14.05 14.05 0 00-4.57.77c-2.36.86-3.71 2.22-5.87 3.38a12.66 12.66 0 01-6 1.68 9.47 9.47 0 01-1.5-.11 38.76 38.76 0 002.42-13.89v-107c0-4.59-.62-6-2.71-10h24.42l17.73 50.31v-50.21z" />
      <path
        d="M1171.89 278.7a46.77 46.77 0 001.07-9.93v-107a20.07 20.07 0 00-.86-6.63h16.82l16.86 48a3.35 3.35 0 003.17 2.25 3.66 3.66 0 00.57-.05 3.35 3.35 0 002.79-3.31v-46.86h8.89v102.39h-12.27l-20.59-66.88a3.34 3.34 0 00-3.21-2.37h-.5a3.37 3.37 0 00-2.86 3.33v22.84c0 21.44-.07 50.8 0 53.23a25.64 25.64 0 00.75 6.05 15.62 15.62 0 00-3 .76 19.34 19.34 0 00-4.32 2.34c-.66.44-1.28.86-2 1.23a13.34 13.34 0 01-1.31.61z"
        fill="#c51f30"
      />
      <path d="M1046.94 151.79a141.78 141.78 0 0123.81 2.23v23.64c-3.71-6.7-7.49-11.29-15.9-11.29-5.64 0-9.89 2.77-12 8.94-2 5.84-2.24 22.59-2.25 38.77 0 14.82-.83 36.21 15.28 36.21 6.26 0 10.52-3.15 14.37-7.67v19.57a74.88 74.88 0 01-18.21 2.26c-8.6 0-18.15-2.2-23.53-8.41-8.46-9.74-10.68-27.33-10.67-41.84-.04-31.82 3.25-62.41 29.1-62.41z" />
      <path
        d="M1052 261.13c-9.35 0-17-2.65-21-7.25-6.36-7.33-9.86-21.41-9.86-39.66 0-13.31.53-30.11 4.69-42 4.05-11.61 10.75-17 21.1-17a133.94 133.94 0 0120.44 1.72v10.65a18 18 0 00-12.54-4.51c-7.3 0-12.71 4-15.21 11.2-2 5.8-2.42 19.56-2.42 39.88v2c0 11.49-.07 25.79 7.17 33.05a15.61 15.61 0 0011.49 4.47 19.12 19.12 0 0011-3.35v9.22a70.93 70.93 0 01-14.86 1.58z"
        fill="#c51f30"
      />
      <path
        d="M1114.68 245.77a9.89 9.89 0 002 3.16l5.62 5.62-5.62-5.62a9.89 9.89 0 01-2-3.16zM1112.15 233.77c-.12-1.06-.21-2.13-.29-3.23.08 1.1.14 2.17.29 3.23zM1112.52 236.85zM1114.28 244.73c-.25-.73-.49-1.51-.7-2.34.21.83.42 1.61.7 2.34z"
        fill="none"
      />
      <path d="M1118.56 151.79c26.26 0 31.18 26.2 31.17 54.38 0 31.21-4.84 57.87-30.49 57.87-26.07 0-30.37-24.35-30.36-57 .01-26.74 4.43-55.25 29.68-55.25zm.79 98.35c9.74 0 7.91-31.09 7.91-43.29 0-15 .47-40.82-7.94-40.82s-8 24.75-8 39c0 12.61-.94 45.13 8 45.13" />
      <path
        d="M1119.28 260.72c-10.79 0-17.74-4.52-21.87-14.21-4.56-10.69-5.14-26.67-5.14-39.4 0-35.44 8.35-51.94 26.3-51.94 10.79 0 17.9 4.65 22.38 14.64 3.72 8.3 5.45 19.86 5.45 36.39 0 38.21-8.11 54.52-27.12 54.52zm0-98a7.53 7.53 0 00-5.39 2.31c-5.53 5.54-6 20.41-5.92 37.84v5.41c-.1 18.48.31 37 6.27 43a7.12 7.12 0 005.11 2.22 7.72 7.72 0 005.58-2.42c5.77-5.85 6-21.81 5.74-39.05 0-2-.06-3.72-.06-5.19v-2.22c0-18.39-.41-34.07-6-39.7a7.34 7.34 0 00-5.28-2.23z"
        fill="#c51f30"
      />
      <path d="M982 151.79l19.67 109.38h-23.08L974 233h-16.53c-.41 3.22-3.56 18.54-3.56 21.86a13.5 13.5 0 001.85 6.32h-18.32l18.19-95.37c.73-3.53 1.38-6.49 1.22-8.77a10.6 10.6 0 00-1.5-5.24zm-22.1 66.42h11.79l-5.11-34.43-6.68 34.43" />
      <path
        d="M981.49 257.85l-4.14-25.37a3.36 3.36 0 00-3.32-2.82H957.5a3.38 3.38 0 00-3.34 2.94c-.13 1-.63 3.76-1.15 6.64-1.42 7.72-2.42 13.36-2.42 15.65a14 14 0 00.33 3h-9.38L959 166.45l.11-.52c.72-3.49 1.34-6.49 1.16-9.11q-.06-.85-.18-1.65h19.14l18.5 102.68zm-14.91-77.41a3.36 3.36 0 00-3.3 2.72l-6.68 34.44a3.36 3.36 0 003.31 4h11.79a3.37 3.37 0 003.33-3.86l-5.13-34.44a3.35 3.35 0 00-3.25-2.86z"
        fill="#c51f30"
      />
      <path d="M230.92 21.24v20.22c-5.69-6.27-6.88-6.56-13.61-6.56h-2.18c-3.7 0-7.29.11-10.88.11V53.2h22.88v14.93h-22.9v48.31h16.41c5.64 0 6.57-2.06 10.47-6.31v20.44h-48.35V31.46c0-4.94-.51-6.08-2.66-10.22z" />
      <path
        d="M186.17 127.24V31.48a21.3 21.3 0 00-.82-6.86h42.22v9.11c-2.85-1.92-5.44-2.17-10.24-2.17h-2.2c-1.89 0-3.76 0-5.57.05s-3.55.06-5.31.06a3.37 3.37 0 00-3.36 3.33v18.22a3.36 3.36 0 003.36 3.36h19.53v8.21h-19.53a3.37 3.37 0 00-3.36 3.36v48.33a3.37 3.37 0 003.36 3.36h16.42a12.94 12.94 0 007.11-1.69v9.09z"
        fill="#c51f30"
      />
      <path d="M285.72 130.57c0-.19.06-3.79.07-5.75.1-18.6-2.19-45.68-10.14-53.55v59.3h-21.46V30.95c0-4-.82-6-2.67-9.41h33c15.59 0 21.94 7.29 22.49 23.92.28 8.38-2.32 18-11.78 21.14 8.29 7.86 10.94 30.32 12 55.81a18.26 18.26 0 002.25 8.16zm-9.61-74.69c6.84 0 9.27-2.63 8.86-9.88-.38-6.76-2.22-10.79-7.36-10.79h-1.95v20.67h.46" />
      <path
        d="M289.18 127.24v-2.36a210.57 210.57 0 00-2.1-31.34c-1.9-12.28-5-20.57-9.06-24.64a3.38 3.38 0 00-2.36-1 3.47 3.47 0 00-1.3.26 3.37 3.37 0 00-2.07 3.1v55.95h-14.7V31a19.69 19.69 0 00-.83-6.06h27.82c13.45 0 18.64 5.6 19.14 20.68.42 12.61-5.76 16.59-9.48 17.83a3.36 3.36 0 00-1.24 5.61c8.17 7.75 10.17 34.26 11 53.53a21.29 21.29 0 00.68 4.65zm-13.5-95.37a3.36 3.36 0 00-3.37 3.36V55.9a3.36 3.36 0 003.31 3.36h.5c2.8 0 6.82-.33 9.52-3.19s2.88-7.26 2.71-10.25c-.53-9.26-4.14-14-10.72-14z"
        fill="#c51f30"
      />
      <path d="M385.13 21.18h25.29l-.11 109.39h-23.6a26 26 0 001.65-6.2c.22-2.8.09-66.57.08-69.92l-19.8 68.63-21.35-66.29c0 3.21.28 64.36.28 67a15.3 15.3 0 002 6.78h-17.25V29.45c0-2.87-1.24-5.89-2.37-8.27h26.58l14.65 46.22z" />
      <path
        d="M391.33 127.24a16.22 16.22 0 00.42-2.57c.23-2.85.1-63.32.08-70.21a3.36 3.36 0 00-2.89-3.32h-.47a3.36 3.36 0 00-3.23 2.43L368.5 111.6l-18-55.82a3.37 3.37 0 00-3.2-2.33 3.23 3.23 0 00-.53 0 3.36 3.36 0 00-2.77 3.38c0 .64.3 64.39.3 67a13.65 13.65 0 00.44 3.41h-9V29.47a17.21 17.21 0 00-.79-4.91h19.18L368 68.45a3.37 3.37 0 003.21 2.34 3.37 3.37 0 003.2-2.39l13.21-43.84h19.43L407 127.24z"
        fill="#c51f30"
      />
      <path d="M643.42 21.46v25.2c-3.81-6.51-6.62-9.8-11.8-9.8h-4.32v99.44c0 5.4 1.15 11.39 3.39 15.54a20 20 0 01-2.82.2 20.55 20.55 0 01-8.7-1.85c-3.18-1.54-4.89-3.21-8.25-4.34a9.63 9.63 0 00-2.87-.38 18.32 18.32 0 00-4.64.62 25.33 25.33 0 002.32-11V36.87h-4.41c-4.52 0-7.82 3.48-11 9.57v-25z" />
      <path
        d="M625.75 148.59a16.5 16.5 0 01-5.09-1.39 33.52 33.52 0 01-3-1.69 25.71 25.71 0 00-5.66-2.81 12.42 12.42 0 00-3.66-.55 31.56 31.56 0 00.77-7V36.88a3.35 3.35 0 00-3.36-3.36h-4.41a11.86 11.86 0 00-7.68 2.85V24.84h46.39v11.78a12.35 12.35 0 00-8.44-3.1h-4.31a3.36 3.36 0 00-3.3 3.36v99.45a42.19 42.19 0 001.75 12.26z"
        fill="#c51f30"
      />
      <g clipPath="url(#prefix__a)">
        <path
          d="M98.46 17.56l5.62 5.62a11.66 11.66 0 00-1.1-1l-5.62-5.62a11.66 11.66 0 011.1 1"
          fill="#74919d"
        />
      </g>
      <g clipPath="url(#prefix__b)">
        <path
          d="M97.53 16.64l5.62 5.62a12.29 12.29 0 00-1.09-1l-5.62-5.62a12.29 12.29 0 011.09 1"
          fill="#5e7079"
        />
      </g>
      <g clipPath="url(#prefix__c)">
        <path
          d="M95.68 14.78l5.62 5.62c-.49-.48-.89-.82-1.1-1l-5.62-5.62c.21.17.61.51 1.1 1"
          fill="#495b64"
        />
      </g>
      <path d="M159.68 21l-15.24 87.21c-1.09 6.11-2.23 12.7-2.24 15.69 0 2.18.93 4.59 1.44 6.66H118c-.92-5.32-18.38-106.39-19.16-110.31-.68-3.44-3.58-5.92-4.27-6.47 2.8-1.23 5.77-2.69 8.82-4.33A104.83 104.83 0 00118 0c2.87 19 14.08 94.44 14.67 98.41L144.52 21z" />
      <path
        d="M120.89 127.24c-3-17.16-18-104-18.73-107.61a12.9 12.9 0 00-2-4.69q2.43-1.18 4.87-2.51A120.32 120.32 0 00115.53 6c3.8 25.25 13.77 92.18 13.87 92.91a3.38 3.38 0 003.32 2.87 3.36 3.36 0 003.33-2.78l11.37-74.6h8.28l-14.53 83.28c-1.2 6.73-2.28 13-2.28 16.26a15 15 0 00.41 3.3z"
        fill="#c51f30"
      />
      <path
        d="M456.67 112.3a10 10 0 002 3.16l5.62 5.62-5.62-5.62a10 10 0 01-2-3.16zM454.14 100.3c-.11-1.06-.21-2.14-.29-3.23.08 1.09.15 2.17.29 3.23zM454.51 103.38zM456.27 111.26a33.3 33.3 0 01-.7-2.35c.22.84.43 1.62.7 2.35z"
        fill="none"
      />
      <path d="M460.55 18.32c26.27 0 31.18 26.2 31.18 54.38 0 31.21-4.85 57.87-30.5 57.87-26.07 0-30.37-24.35-30.36-57 .01-26.74 4.43-55.25 29.68-55.25zm.79 98.35c9.74 0 7.91-31.09 7.91-43.29 0-15 .47-40.83-7.94-40.83s-8 24.76-8 39c0 12.6-.94 45.13 8 45.13" />
      <path
        d="M461.27 127.24c-10.79 0-17.73-4.51-21.87-14.21-4.56-10.69-5.14-26.67-5.14-39.4 0-35.43 8.36-51.93 26.3-51.93 10.79 0 17.91 4.65 22.38 14.63 3.72 8.3 5.45 19.87 5.45 36.4 0 38.21-8.11 54.51-27.12 54.51zm.05-98a7.49 7.49 0 00-5.38 2.31c-5.53 5.54-6 20.42-5.93 37.85v5.48c-.1 18.47.31 37 6.27 43a7.14 7.14 0 005.11 2.22 7.74 7.74 0 005.59-2.42c5.76-5.86 6-21.81 5.73-39.05 0-2-.06-3.72-.06-5.19v-2.26c0-18.39-.41-34.06-6-39.69a7.35 7.35 0 00-5.33-2.28z"
        fill="#c51f30"
      />
      <path d="M569.85 21.46v109.11h-18.14l-21.3-69.25c0 7.24-.11 42.72-.11 49.95s-.74 12.87 2.71 19.3h-18.15V31.46c0-4.6-.62-6-2.71-10h24.45l17.63 50.2v-50.2z" />
      <path
        d="M554.23 127.24l-20.58-66.88a3.37 3.37 0 00-3.21-2.36 4.21 4.21 0 00-.5 0 3.37 3.37 0 00-2.87 3.33c0 3.61 0 14.29-.05 25s0 21.37 0 25v3.16c-.07 4.31-.14 8.44 1.12 12.77h-9.79V31.48a20.3 20.3 0 00-.86-6.63h16.82l16.78 47.95a3.37 3.37 0 003.17 2.25 3.64 3.64 0 00.56 0 3.37 3.37 0 002.8-3.31V24.85h8.89v102.39z"
        fill="#c51f30"
      />
      <path d="M1112.46 20.9v20.22c-5.69-6.27-6.88-6.55-13.61-6.55h-2.18c-3.7 0-7.29.11-10.88.11v18.18h22.88v14.93h-22.88v48.32h16.41c5.64 0 6.57-2.07 10.47-6.32v20.44h-48.37V31.12c0-4.94-.51-6.08-2.66-10.22z" />
      <path
        d="M1067.71 126.91V31.15a21.37 21.37 0 00-.82-6.87h42.22v9.11c-2.85-1.92-5.44-2.16-10.24-2.16h-2.2c-1.89 0-3.76 0-5.57.06s-3.55.05-5.31.05a3.37 3.37 0 00-3.36 3.37v18.17a3.37 3.37 0 003.36 3.37h19.53v8.2h-19.53a3.37 3.37 0 00-3.36 3.37v48.32a3.36 3.36 0 003.36 3.36h16.42a13 13 0 007.11-1.68v9.09z"
        fill="#c51f30"
      />
      <path d="M1022.48 20.9v94.92h10.88c5.64 0 6.56-2.06 10.46-6.31V130H1001V31.75a18.82 18.82 0 00-2.66-10.8z" />
      <path
        d="M1004.41 126.62v-94.9a23.36 23.36 0 00-.92-7.43h15.63v91.57a3.36 3.36 0 003.37 3.36h10.88a12.92 12.92 0 007.1-1.69v9.09z"
        fill="#c51f30"
      />
      <path d="M950.57 77.55l-.12 37.65c-.1 7.12-.41 8.38 2.77 14.7h-24.11V31.95c0-5.53-.92-7.72-2.67-11h31.6c15.19 0 22.91 11.25 23.39 27 .78 24.21-10.23 29.6-30.86 29.6zm0-43v28.76c7 0 9.44-6.22 8.94-14.3-.39-6.2-.74-14.44-7.39-14.44h-1.54" />
      <path
        d="M932.51 126.58V32a26.41 26.41 0 00-.93-7.68h26.5c6.26 0 11.18 2.13 14.61 6.35s5.21 10.11 5.44 17.4c.3 9.73-1.39 16.36-5.18 20.26-4 4.15-10.7 5.92-22.35 5.92a3.37 3.37 0 00-3.37 3.35l-.1 37.66v1.54c-.08 4.36-.06 6.7 1 9.81zm18.09-95.35a3.37 3.37 0 00-3.37 3.36v28.75a3.36 3.36 0 003.37 3.36 11.27 11.27 0 008.47-3.45c2.89-3.08 4.21-8.07 3.81-14.43v-.46c-.2-3.3-.44-7-1.58-10.22-2.16-6-6.65-6.91-9.14-6.91z"
        fill="#c51f30"
      />
      <path d="M801.22 20.9h25.29l-.11 109.39h-23.6a25.5 25.5 0 001.64-6.2c.23-2.8.1-66.56.09-69.92l-19.8 68.64-21.35-66.3c0 4 .23 79.22.22 79.8a22.7 22.7 0 002 9.4 9.39 9.39 0 00-1.91-.18 13.68 13.68 0 00-4.47.77c-2.35.86-3.63 2.23-5.78 3.38a12.57 12.57 0 01-6 1.69 9.61 9.61 0 01-1.51-.12 38.5 38.5 0 002.43-13.89V29.17c0-2.87-1.25-5.88-2.37-8.27h26.58l14.64 46.23z" />
      <path
        d="M750.73 147.33a47.36 47.36 0 001.07-9.93V29.19a17.49 17.49 0 00-.78-4.9h19.17l13.92 43.88a3.36 3.36 0 003.2 2.35 3.35 3.35 0 003.19-2.4l13.22-43.83h19.43L823.08 127h-15.66a19.29 19.29 0 00.42-2.58c.22-2.85.09-63.32.08-70.2a3.36 3.36 0 00-2.9-3.32 2.49 2.49 0 00-.47 0 3.37 3.37 0 00-3.23 2.43l-16.74 58.06-18-55.81a3.37 3.37 0 00-3.2-2.34 3.27 3.27 0 00-.53 0 3.35 3.35 0 00-2.85 3.31c0 .78.25 78.65.24 79.77a23.5 23.5 0 00.73 6.12 17.51 17.51 0 00-2.85.74 18.84 18.84 0 00-4.27 2.34 19.92 19.92 0 01-1.95 1.23 11 11 0 01-1.17.58z"
        fill="#c51f30"
      />
      <path d="M888.93 20.9l19.67 109.39h-23.07l-4.59-28.18h-16.53c-.41 3.21-3.55 18.53-3.55 21.86a13.61 13.61 0 001.84 6.32h-18.32l18.2-95.37c.72-3.53 1.37-6.49 1.22-8.77a10.82 10.82 0 00-1.5-5.25zm-22.11 66.43h11.8l-5.12-34.44-6.68 34.44" />
      <path
        d="M888.43 127l-4.13-25.4a3.36 3.36 0 00-3.3-2.82h-16.55a3.36 3.36 0 00-3.34 2.93c-.13 1-.63 3.77-1.16 6.65-1.41 7.72-2.41 13.36-2.41 15.65a13.26 13.26 0 00.33 2.95h-9.38l17.4-91.39.11-.57c.72-3.48 1.34-6.49 1.17-9.1q-.06-.87-.18-1.65h19.14L904.62 127zm-14.91-77.45a3.36 3.36 0 00-3.3 2.73l-6.67 34.44a3.36 3.36 0 003.3 4h11.8a3.37 3.37 0 003.35-3.86l-5.13-34.44a3.37 3.37 0 00-3.25-2.87z"
        fill="#c51f30"
      />
    </svg>
  )
}

export default TaglineDesktop
